import React, { useState } from "react";
import { useTelegram } from "../../contexts/TelegramContext";

const Folders = () => {
  const { folderData, fetchFolderData } = useTelegram();
  const [folderNameInput, setFolderNameInput] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetchFolderData(folderNameInput);
  };

  const FolderItem = ({ data }) => {
    const membersCount = data.chatMembers.length;
    return (
      <ul className="folder-item">
        <li>
          <h5>
            {data.chatTitle} | Members Count: {membersCount}
          </h5>
          {/* Render additional data here */}
        </li>
      </ul>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Folder Name"
          value={folderNameInput}
          onChange={(e) => setFolderNameInput(e.target.value)}
        />
        <button type="submit">get folders</button>
      </form>
      <div>
        {folderData ? (
          <div>
            {folderData.map((data, index) => (
              <FolderItem key={index} data={data} />
            ))}
          </div>
        ) : (
          <div>
            <p>No Folder found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Folders;
