/**
 * The purpose of this module is to represent a cache / db, depending on how it wants to be implemented
 * Store chatId of group/channel, where the ownership transfer is already done.
 * this is because the group is technically still present in the folder, after UserA has left the group
 * chats that are stored here are then to be ignored if the task needs to be executed again
 */

/**
 * This function represents saving data to a DB or a Cache.
 * @param {*} fileName
 * @param {*} dataToSave -> list of chatIds appended to localStorage on browser
 */
exports.saveData = (fileName, dataToSave) => {
  const fName = `${fileName}Completed`;
  localStorage.setItem(fName, JSON.stringify(dataToSave));
};

/**
 * This function represents loading data from a db/cache
 * @param {*} fileName
 * @returns list of chatIds, where ownership has been transferred to UserB
 */
exports.loadData = (fileName) => {
  const data = localStorage.getItem(fileName);
  return data ? JSON.parse(data) : null;
};
