const { TelegramClient } = require("telegram");
const { StringSession } = require("telegram/sessions");

const apiId = Number(process.env.REACT_APP_TG_APP_API_ID);
const apiHash = process.env.REACT_APP_TG_APP_API_HASH;
const stringSession = new StringSession(process.env.REACT_APP_SESSION);

exports.loginWithSessionString = (strSession) => {
  const ss = new StringSession(strSession);
  const client = new TelegramClient(ss, apiId, apiHash, {
    connectionRetries: 2,
  });
  return client;
};

/**
 * @param {String} phoneNumber - phonenumber of the account including the area code.
 * @param {String} password - 2fa password for TG
 * @param {String} phoneCode - code given by TG when trying to log in
 * @returns
 */
exports.login = async (phoneNumber, password, phoneCode) => {
  const client = new TelegramClient(stringSession, apiId, apiHash, {
    connectionRetries: 2,
  });
  await client.start({
    phoneNumber: () => Promise.resolve(phoneNumber),
    password: () => Promise.resolve(password),
    phoneCode: () => Promise.resolve(phoneCode),
    // onError: (e) => console.log(e),
  });
  return client;
};

// Optionally handle Node.js app termination
