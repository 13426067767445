import React, { useState } from "react";
import { useTelegram } from "../../contexts/TelegramContext";

const Login = () => {
  const { tgClientAuth, loginTg } = useTelegram();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [phoneCode, setPhoneCode] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginTg(phoneNumber, password, phoneCode);
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="login-container">
      {tgClientAuth ? (
        <p>Logged In</p>
      ) : (
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="text"
            placeholder="Code"
            value={phoneCode}
            onChange={(e) => setPhoneCode(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
      )}
    </div>
  );
};

export default Login;
