import React from "react";
import Login from "../Login/Login";
import Folders from "../Folders/Folders";
import HandleGroups from "../HandleGroups/HandleGroups";

const MainLayout = () => {
  return (
    <div className="main-layout">
      <div className="login-column">
        <Login />
      </div>
      <div className="folders-column">{<Folders />}</div>
      <div className="groups-column">{<HandleGroups />}</div>
    </div>
  );
};

export default MainLayout;
