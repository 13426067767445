
/**
 * Helper to get chatId from the correct attribute
 * @param {*} className see chats.js for explanation to arg
 * @returns 
 */
exports.getCIdField = (className) => {
  if (className === "InputPeerChat") {
    return "chatId";
  } else if (className === "InputPeerChannel") {
    return "channelId";
  }
};
