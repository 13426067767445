// const { Api } = require("telegram");
const { Chats } = require("./actions/chats");
const { Folders } = require("./actions/folders");

exports.PermissionTool = class PermissionTool {
  constructor(client) {
    this.client = client;
    this.folders = new Folders(this.client);
    //intially instantiate with nulls
    this.chats = new Chats(this.client, undefined, undefined, undefined);
  }

  //HELPERS--------------------------------------------------------------------------
  checkUserInGroup = async (username) => {
    if (!this.chats.chatId && !this.chats.accessHash && !this.chats.className) {
      throw new Error(
        "Please add values to chats.id, chats.accessHash, and this.className"
      );
    }
    const chatInfo = await this.folders.getChatMembersAndTitle(
      this.chats.chatId,
      this.chats.accessHash,
      this.chats.className
    );
    const check = chatInfo.chatMembers.find((cm) => cm.username === username);
    return check ? true : false;
  };

  //UNUSED BUT MAYBE USEFUL IF NEEDED--------------------------------------------------------------------------
  //   getUserAccessHashAndId = async (username) => {
  //     const res = await this.client.invoke(
  //       new Api.users.GetFullUser({
  //         id: username,
  //       })
  //     );
  //     return {
  //       accessHash: res.users[0].accessHash,
  //       id: res.users[0].id,
  //     };
  //   };

  //   getFullChannel = async (channelName) => {
  //     const res = await this.client.invoke(
  //       new Api.channels.GetFullChannel({
  //         channel: "-1001815338461",
  //       })
  //     );
  //     console.log(res);
  //   };
  // };
};
