import "./App.css";
import React from "react";
import { TelegramProvider } from "./contexts/TelegramContext";
// import { Login } from "./components";
import { MainLayout } from "./components";

function App() {
  return (
    <TelegramProvider>
      <div className="App">
        <header className="App-header">
          <div>
            <MainLayout></MainLayout>
          </div>
        </header>
      </div>
    </TelegramProvider>
  );
}

export default App;
