const { Api } = require("telegram");

exports.Folders = class Folders {
  constructor(client) {
    this.client = client;
  }

  getFolders = async () => {
    try {
      const res = await this.client.invoke(
        new Api.messages.GetDialogFilters({})
      );
      return res;
    } catch (e) {
      console.error("ERROR: Cannot get folders for account");
      console.error(e.message);
    }
  };

  getWantedFolder = async (folderName) => {
    try {
      const folders = await this.getFolders();
      if (!folders) return;

      const wantedFolder = folders.filter(
        (f) => f.title?.toLocaleLowerCase() === folderName.toLocaleLowerCase()
      )[0];
      if (!wantedFolder)
        throw new Error(`Folder with name - ${folderName} not found!`);
      return wantedFolder;
    } catch (e) {
      console.error("ERROR: Cannot get folder");
      console.error(e.message);
    }
  };

  /**
   * Function gets all members of chat and the name of the channel. Needed to identify if UserB is present
   * See chats.js to understand params
   * @param {*} chatId 
   * @param {*} accessHash 
   * @param {*} className 
   * @returns 
   */
  getChatMembersAndTitle = async (chatId, accessHash, className) => {
    var command;
    try {
      switch (className) {
        case "InputPeerChannel":
          const filter = new Api.ChannelParticipantsRecent({});
          const channel = new Api.InputChannel({
            channelId: chatId,
            accessHash: accessHash,
          });
          command = new Api.channels.GetParticipants({
            channel: channel,
            filter: filter,
            limit: 100,
          });
          const chatMembersRes = await this.client.invoke(command);
          const chatTitleRes = await this.client.invoke(
            new Api.channels.GetChannels({
              id: [channel],
            })
          );
          return {
            chatMembers: chatMembersRes.users,
            chatTitle: chatTitleRes.chats[0].title,
            chatId: chatId,
          };
        case "InputPeerChat":
          command = new Api.messages.GetFullChat({
            chatId: chatId,
          });
          const res = await this.client.invoke(command);
          return {
            chatMembers: res.users,
            chatTitle: res.chats[0].title,
            chatId: chatId,
          };
        default:
          throw new Error("this className is not being handled:", className);
      }
    } catch (e) {
      console.error("ERROR: Cannot get group participants");
      console.error(e.message);
    }
  };
};
