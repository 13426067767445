const { Api, password } = require("telegram");

exports.Chats = class Chats {
  /**
   *
   * @param {*} client - client instance returned by client.js
   * @param {*} chatId - chatId for the chat inside a folder returned from getWantedFolder() in folders.js
   * @param {*} accessHash - accesshash (if present, otherwise undefined) for the chat inside a folder returned from getWantedFolder() in folders.js
   * @param {*} className
   */
  /**
   * Class representing a chat.
   * @class
   * @param {Client} client - Client instance returned by client.js.
   * @param {number|string} chatId - Chat ID for the chat returned from getWantedFolder() in folders.js
   * @param {string} [accessHash] - Access hash for the chat returned from getWantedFolder() in folders.js, if available. Else, undefined
   * @param {string} className - className returned for the from getWantedFolder() in folders.js
   *
   */
  constructor(client, chatId, accessHash, className) {
    this.client = client;
    this.chatId = chatId;
    this.accessHash = accessHash;
    this.className = className;
  }
  /**
   * inputs represent arguments same as above. Use this to access another
   * chat without instantiating another object
   */
  updateInstance = (chatId, accessHash, className) => {
    this.chatId = chatId;
    this.accessHash = accessHash;
    this.className = className;
  };

  /**
   *
   * @param {String} username - UserB username
   */
  addUser = async (username) => {
    try {
      let res;
      switch (this.className) {
        case "InputPeerChannel":
          console.log("adding user to channel");
          res = await this.client.invoke(
            new Api.channels.InviteToChannel({
              channel: new Api.InputChannel({
                channelId: this.chatId,
                accessHash: this.accessHash,
              }),
              users: [username],
            })
          );
          break;
        case "InputPeerChat":
          console.log("adding user to channel");
          res = await this.client.invoke(
            new Api.messages.AddChatUser({
              chatId: this.chatId,
              userId: username,
              fwdLimit: 0,
            })
          );
          const userAdded = res.users.find((cm) => cm.username === username);
          if (!userAdded) throw new Error("Add User Unsuccessful");
          break;
        default:
          throw new Error(
            "this className is not being handled:",
            this.className
          );
      }
      return res;
    } catch (e) {
      console.error(`ERROR: Cannot get add ${username} to group`);
      console.error(e);
    }
  };

  /**
   * Leave the group as UserA.
   * Only use after ownership has been transferred to UserB
   */
  leaveGroup = async () => {
    const me = await this.client.getMe();
    try {
      let command;
      switch (this.className) {
        case "InputPeerChannel":
          command = new Api.channels.LeaveChannel({
            channel: new Api.InputChannel({
              channelId: this.chatId,
              accessHash: this.accessHash,
            }),
          });
          break;
        case "InputPeerChat":
          command = new Api.messages.DeleteChatUser({
            chatId: this.chatId,
            userId: me.username,
            revokeHistory: false,
          });
          break;
        default:
          throw new Error(
            "this className is not being handled:",
            this.className
          );
      }
      const res = await this.client.invoke(command);
      return res;
    } catch (e) {
      console.error(e.message);
      console.error(`ERROR: Cannot get leave group/channel`);
    }
  };

  /**
   *
   * @param {String} newAdminId - UserB username (future owner of group)
   * @param {String} password - 2fa password set for telegram
   */
  setOwner = async (newAdminId, password) => {
    try {
      let res;
      switch (this.className) {
        case "InputPeerChannel":
          res = await this._setChannelOwner(newAdminId, password);
          break;
        case "InputPeerChat":
          res = this._setGroupOwner(newAdminId, password);
          break;
        default:
          throw new Error(
            "this className is not being handled:",
            this.className
          );
      }
      return res;
    } catch (e) {
      console.error(`ERROR: Cannot set ${newAdminId} as owner`);
      console.error(e.message);
      console.error(e);
    }
  };

  // HELPERS---------------------------------------------------------------------------
  /**
   * This function is required to check whether the 2FA password give is correct
   * Only needed for channels / supergroups
   * @param {String} newAdminId - UserB username (future owner of group)
   * @param {String} password - 2fa password set for telegram
   */
  _setChannelOwner = async (newAdminId, password) => {
    const { srpId, A, M1 } = await this._getPassword(password);
    const inputPassword = new Api.InputCheckPasswordSRP({
      srpId: srpId,
      A: A,
      M1: M1,
    });

    const inputChannel = new Api.InputChannel({
      channelId: this.chatId,
      accessHash: this.accessHash,
    });
    const command = new Api.channels.EditCreator({
      channel: inputChannel,
      userId: newAdminId,
      password: inputPassword,
    });
    const res = this.client.invoke(command);
    return res;
  };

  /**
   * used only for chats/normal groups. After a new admin has been asigned (userB)
   * then chat will turn to a super group. Then _setChannelOwner() function will be called
   * @param {String} newAdminId - UserB username (future owner of group)
   * @param {String} password - 2fa password set for telegram
   */
  _setGroupOwner = async (newAdminId, password) => {
    try {
      const migrationRes = await this.client.invoke(
        new Api.messages.MigrateChat({
          chatId: this.chatId,
        })
      );
      const info = migrationRes.chats[0].migratedTo;

      this.updateInstance(info.channelId, info.accessHash, "InputPeerChannel");
      const res = await this._setChannelOwner(newAdminId, password);
      return res;
    } catch (e) {
      console.error("ERROR: Cannot get group participants");
      console.error(e.message);
    }
  };
  /**
   * to be used to check input password
   * @param {String} passwordString - 2fa password set for telegram
   */
  _getPassword = async (passwordString) => {
    try {
      const pw = await this.client.invoke(new Api.account.GetPassword());
      return await password.computeCheck(pw, passwordString);
    } catch (e) {
      console.error(`ERROR: Cannot get or verify password to group`);
      console.error(e);
    }
  };
};
