import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { login, loginWithSessionString } from "../modules/telegram/client";
import { PermissionTool } from "../modules/telegram/api";
import { getCIdField } from "../modules/telegram/helpers";
import { loadData, saveData } from "../modules/browserStorage/storage";

const TelegramContext = createContext();

export const useTelegram = () => useContext(TelegramContext);

export const TelegramProvider = ({ children }) => {
  const [tgClient, setTgClient] = useState(null);
  const [tgClientAuth, setTgClientAuth] = useState(false);
  const [permissionTool, setPermissionTool] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [folder, setFolder] = useState(null);
  const [folderFromStorage, setFolderFromStorage] = useState([]);

  async function checkSession(client) {
    const authorized = await client.checkAuthorization();
    console.log(authorized);
    setTgClientAuth(authorized);
    setTgClient(client);
    return authorized;
  }

  useEffect(() => {
    // const sessionString = process.env.REACT_APP_SESSION;
    let sessionString;

    if (process.env.REACT_APP_SESSION && process.env.REACT_APP_SESSION !== "") {
      console.log("using .env session");
      sessionString = process.env.REACT_APP_SESSION;
    } else {
      console.log("using locally stored session ");
      const storedSession = loadData("userSessionDataCompleted");
      if (storedSession) {
        sessionString = storedSession;
      }
    }
    const client = loginWithSessionString(sessionString);
    client
      .connect()
      .then(async () => {
        const authorized = await checkSession(client);
        if (authorized) console.log("Connected to account using session Id");
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (tgClientAuth && tgClient) {
      const newPermissionTool = new PermissionTool(tgClient);
      setPermissionTool(newPermissionTool);
    }
  }, [tgClientAuth, tgClient]); // This effect depends on tgClientAuth and tgClient

  const loginTg = useCallback(async (phoneNumber, password, phoneCode) => {
    try {
      const clientInstance = await login(phoneNumber, password, phoneCode);
      const authorized = await checkSession(clientInstance);

      //for the moment the string session is just copy pasted
      console.log("connected");
      saveData("userSessionData", clientInstance.session.save());
    } catch (error) {
      console.error("Failed to log in:", error);
    }
  }, []);

  const fetchFolderData = useCallback(
    async (folderName) => {
      //get folder data
      const folderInfo = await permissionTool.folders.getWantedFolder(
        folderName
      );
      if (!folderInfo) return;

      //Load stored folder data
      let folderFromStorageTmp = loadData(folderInfo.title);

      if (folderFromStorageTmp == null) {
        folderFromStorageTmp = [];
      }

      const filteredFolder = folderInfo.includePeers.filter((f) => {
        const chatId = f[getCIdField(f.className)];
        return (
          !folderFromStorageTmp.includes(chatId) &&
          (f.className === "InputPeerChat" ||
            f.className === "InputPeerChannel")
        );
      });
      folderInfo.includePeers = filteredFolder;

      const promise = filteredFolder.map(async (f) => {
        const chatId = f[getCIdField(f.className)];
        const data = await permissionTool.folders.getChatMembersAndTitle(
          chatId,
          f.accessHash,
          f.className
        );
        return data;
      });

      const folderData = await Promise.all(promise);
      var dataNoNulls = folderData.filter((e) => {
        return e != null;
      });
      setFolder(folderInfo);
      setFolderData(dataNoNulls);
      setFolderFromStorage(folderFromStorageTmp);
    },
    [permissionTool, folderFromStorage]
  );

  const setStorageDataForContext = useCallback((dataToAppend) => {
    setFolderFromStorage([...folderFromStorage, dataToAppend]);
  }, []);

  return (
    <TelegramContext.Provider
      value={{
        tgClient,
        tgClientAuth,
        permissionTool,
        folder,
        folderData,
        folderFromStorage,

        //FUNCTIONS
        fetchFolderData,
        loginTg,
        setStorageDataForContext,
      }}
    >
      {children}
    </TelegramContext.Provider>
  );
};
