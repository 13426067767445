import React, { useState } from "react";
import "./HandleGroups.css";
import { useTelegram } from "../../contexts/TelegramContext";
import { getCIdField } from "../../modules/telegram/helpers";
import { saveData } from "../../modules/browserStorage/storage";

const HandleGroups = () => {
  const {
    folderData,
    folder,
    permissionTool,
    folderFromStorage,
    setStorageDataForContext,
  } = useTelegram();

  const [currGroup, setCurrGroup] = useState("");
  const [usernameToAdd, setUsernameToAdd] = useState("");
  const [successfulUserAdd, setSuccessfulUserAdd] = useState(false);

  const [newOwnerUsername, setNewOwnerUsername] = useState("");
  const [passwordInput, setPasswordInput] = useState(null);

  function setGroup(chatInfo) {
    setCurrGroup(chatInfo.chatTitle);
    console.log("FOLDER", folder);
    const currChat = folder.includePeers.find((f) => {
      const cId = f[getCIdField(f.className)];
      return cId === chatInfo.chatId;
    });
    permissionTool.chats.updateInstance(
      chatInfo.chatId,
      currChat.accessHash,
      currChat.className
    );
  }

  async function addUser(usernameToAdd) {
    console.log("CURRENT USERNAME IN ADDUSER", usernameToAdd);

    const userInGroup = await permissionTool.checkUserInGroup(usernameToAdd);
    if (!userInGroup) {
      const add = await permissionTool.chats.addUser(usernameToAdd);
      console.log("ADD");
      console.log("successful add");
      if (!add) {
        console.error("Add User Unsuccessful");
        return false;
      }
    } else {
      console.log("Wanted user is already in group");
    }
    return true;
  }
  async function leaveGroup() {
    for (let i = 0; i < folderData.length; i++) {
      const chatInfo = folderData[i];
      //GET THE CORRESPONDING CHAT INFO FOR CURRENT CHAT ID FROM FOLDER VAR
      setGroup(chatInfo);

      const removeSelf = await permissionTool.chats.leaveGroup();
      console.log("REMOVE SELF RESP", removeSelf);

      //IF SUCCESSFUL SELF REMOVAL
      const currStorage = [...folderFromStorage];

      //push chatid to list, then store the list for access later
      currStorage.push(chatInfo.chatId);
      console.log("FOLDER NAME IN HANDLEGROUP", folder.title);
      saveData(folder.title, currStorage);
      setStorageDataForContext(chatInfo.chatId);
    }
    return true;
  }

  const handleAddUser = async (e) => {
    e.preventDefault();
    if (usernameToAdd) {
      console.log("CURRENT USERNAME TO ADD", usernameToAdd);
      setSuccessfulUserAdd(false);
      const addedUser = await addUserToGroups(usernameToAdd);
      if (addedUser) setSuccessfulUserAdd(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newOwnerUsername && passwordInput) {
      await setOwner(newOwnerUsername);
    }
  };

  /**
   * only adds user to the group.
   */
  const addUserToGroups = async () => {
    for (let i = 0; i < folderData.length; i++) {
      const chatInfo = folderData[i];
      //GET THE CORRESPONDING CHAT INFO FOR CURRENT CHAT ID FROM FOLDER VAR
      setGroup(chatInfo);

      //CHECK IF USER(FUTURE ADMIN/OWNER) is IN GROUP + ADD TO GROUP
      const addedUser = await addUser(usernameToAdd);
      if (addedUser)
        console.log(`successfully added user to ${chatInfo.chatTitle}`);
      else {
        console.log(`could not add user to ${chatInfo.chatTitle}`);
      }
    }
    return true;
  };

  /**
   * This function is essentially the full flow to
   * add UserB in group,
   * Transfer ownership to UserB
   * and have UserA leave the group. was unsure where to put this
   */
  const setOwner = async () => {
    for (let i = 0; i < folderData.length; i++) {
      const chatInfo = folderData[i];
      //GET THE CORRESPONDING CHAT INFO FOR CURRENT CHAT ID FROM FOLDER VAR
      setGroup(chatInfo);

      //CHECK IF USER(FUTURE ADMIN/OWNER) is IN GROUP + ADD TO GROUP
      const addedUser = await addUser(newOwnerUsername);
      // if (!addedUser) continue;

      // IF SUCCESSFULL, SET THE USER AS THE NEW OWNER
      const setNewOwner = await permissionTool.chats.setOwner(
        newOwnerUsername,
        passwordInput
      );
      console.log("OWNER RESP", setNewOwner);

      //IF SUCCESSFULL TRANSFER, REMOVE SELF FROM GROUP/CHANNEL
      if (!setNewOwner) {
        continue;
      }
    }
  };

  return (
    <div className="handle-groups-container">
      <div className="form-section">
        <p>Add User to Groups in Folder</p>
        <form onSubmit={handleAddUser}>
          <input
            type="text"
            placeholder="Username"
            value={usernameToAdd}
            onChange={(e) => setUsernameToAdd(e.target.value)}
          />
          <button type="submit">Add User</button>
        </form>
        {successfulUserAdd ? (
          <h5>{`Task: adding ${usernameToAdd} to all groups in folder done`}</h5>
        ) : undefined}
        {usernameToAdd !== "" ? <h5>{`${usernameToAdd}`}</h5> : undefined}
      </div>
      <div className="form-section">
        <p>Set User as Owner</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="New Owner Username"
            value={newOwnerUsername}
            onChange={(e) => setNewOwnerUsername(e.target.value)}
          />
          <button type="submit">Start</button>
          <div>
            <form onSubmit={handleSubmit}>
              <input
                type="password"
                placeholder="Enter TG 2fa password"
                value={passwordInput}
                onChange={(e) => setPasswordInput(e.target.value)}
              />
              {/* <button type="submit">enter password</button> */}
            </form>
          </div>
        </form>
        {currGroup ? <p>Current Group Being Set: {currGroup}</p> : undefined}
      </div>
      <div className="form-section">
        <p>Remove self from groups</p>
        <button onClick={leaveGroup}>Remove Self</button>
      </div>
    </div>
  );
};

export default HandleGroups;
